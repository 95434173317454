import { Box, Button, Heading, Text } from '@chakra-ui/react'
import * as React from 'react'
import { ArrowForwardIcon } from '@chakra-ui/icons'

import { openPopupWidget } from "react-calendly";


const Cta = () => {
    const url = 'https://calendly.com/daniosdigital/videollamada-consultoria-gratis'
    //, prefill, pageSettings, utm
    const onClick = () => openPopupWidget({ url });

    return (


        <Box
            as="section"
            color={'white'}
            bgColor={'transparent'}
            bgGradient="linear(60deg, gradient.blue.start 0%, gradient.blue.end 100%)"
            opacity={0.9}>
            <Box
                maxW="4xl"
                mx="auto"
                px={{ base: '6', lg: '8' }}
                py={{ base: '16', sm: '10' }}
                textAlign="center"

            >
                <Heading as="h2" size="2xl" fontWeight="extrabold" letterSpacing="tight">
                    ¿Listo para crecer?
                </Heading>
                <Text mt="4" fontSize="lg">
                    Solicita una breve reunión virtual sin compromisos para explorar las oportunidades de crecimiento según tu caso particular.

                </Text>
                <Button onClick={onClick} mt="8" as="a" href="#" size="lg" rightIcon={<ArrowForwardIcon />} color={'primary'} bgColor={'white'} fontWeight="bold">
                    Agendar Video Llamada
                </Button>

            </Box>
        </Box>
    )
}

export default Cta